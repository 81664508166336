<template>
  <div data-testid="container-streams">
    <v-card>
      <v-toolbar extended>
        <v-btn
          fab
          dark
          elevation="0"
          @click.stop="refreshTablesData()"
          data-testid="btn-refresh"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-card-title class="break-word pl-0" data-testid="title-streams">
          Streams
        </v-card-title>
        <template v-slot:extension>
          <v-tabs v-model="tab" class="ml-0">
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab v-for="tab in tabItems" :key="tab.id" :to="tab.route">
              {{ tab.name }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-card-text>
        <v-tabs-items v-model="tab" touchless>
          <v-row class="px-0 my-2">
            <v-col cols="12" md="4">
              <!--              <v-text-field-->
              <!--                outlined-->
              <!--                v-model="search"-->
              <!--                append-icon="mdi-magnify"-->
              <!--                label="Search"-->
              <!--                single-line-->
              <!--                hide-details-->
              <!--                style="max-width: 300px;"-->
              <!--              ></v-text-field>-->
            </v-col>
            <v-col cols="12" md="3" v-if="$can('read', 'Superadmin')">
              <SelectSignalsAsync
                v-model="currentSignalKey"
                outlined
                label="Signal server"
                :multiple="false"
                item-text="data.name"
                item-value="_key"
                :menu-props="{ maxHeight: 304 }"
                :hint="signalUrlHint"
                persistent-hint
                data-testid="filter-signal-server"
              />
            </v-col>
            <v-col cols="12" md="3">
              <SelectCompaniesAsync
                v-model="currentStreamsCompany"
                outlined
                label="Company"
                :multiple="false"
                item-value="_key"
                item-text="name"
                v-validate="'required'"
                :error-messages="
                  errors.collect(`form-bundle-event-settings.group_key`)
                "
                data-vv-name="group_key"
                data-vv-validate-on="change"
                data-vv-as="company"
                field="group_key"
                :menu-props="{ maxHeight: 304 }"
                return-object
                :hint="signalRoomHint"
                persistent-hint
                :disabled="currentCommonRoom"
                data-testid="filter-signal-company-room"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-checkbox
                v-model="currentCommonRoom"
                label="Use common room"
                class="mx-2 mt-3"
                dense
                data-testid="chbx-filter-signal-common-room"
              />
            </v-col>
          </v-row>

          <router-view />
        </v-tabs-items>
      </v-card-text>
    </v-card>

    <StreamPreviewDialog />
    <StreamHistoryDialog />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import SelectSignalsAsync from "@/components/selects/SelectSignalsAsync.vue"
import SelectCompaniesAsync from "@/components/selects/SelectCompaniesAsync"
import StreamPreviewDialog from "./StreamPreviewDialog"
import StreamHistoryDialog from "./StreamHistoryDialog"
import StreamCommonMixin from "@/mixins/streams/common"

export default {
  name: "streams-list",

  components: {
    SelectSignalsAsync,
    SelectCompaniesAsync,
    StreamPreviewDialog,
    StreamHistoryDialog,
  },

  mixins: [StreamCommonMixin],

  data: () => ({
    tabItems: [
      { id: "1", name: "active", route: "/engines/streams/active" },
      { id: "2", name: "history", route: "/engines/streams/history" },
    ],
    tab: null,
  }),

  watch: {
    receivedActiveData: function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.$store.dispatch("saveLoaderAction", false)
      }
    },
    receivedInactiveData: function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.$store.dispatch("saveLoaderAction", false)
      }
    },
  },

  mounted: function () {
    let self = this
    self.$store.dispatch("saveLoaderAction", true)
    self.$store
      .dispatch("getUserGroups", { params: { p: 1, pp: 500 } })
      .then(() => {
        self.$store.dispatch("streamsInitActiveCompany").then(() => {
          self.$store
            .dispatch("signalServersList", { params: { p: 1, pp: 500 } })
            .then(() => {
              self.$store.dispatch("streamsInitActiveSignal").then(() => {
                self.createSignalConnection().then(() => {
                  setTimeout(() => {
                    self.$store.dispatch("saveLoaderAction", false)
                  }, 4000)
                })
              })
            })
        })
      })
  },

  methods: {
    // @todo maybe later handle loaded data not set all
    // restartWebrtcConns() {
    //   let self = this
    //   self.$store.commit('RESET_LIST_MEMBERS_WS_CONN')
    //   let remoteClose = self.receiver.disconnect()
    //
    //   Promise.all([remoteClose]).then((values) => {
    //     // self.receiver = null
    //     self.receiver.getOrCreateConnection().then(function () {
    //       self.receiver.getAspxStreamsList()
    //       self.$refs.refSActive.getStreamsData()
    //       self.$refs.refSInactive.getStreamsData()
    //     })
    //   })
    // },
  },

  computed: {
    ...mapGetters({
      streamsPeerReceiver: "streamsPeerReceiver",
      streamActiveItem: "streamActiveItem",
      streamsActive: "streamsActive",
      streamsInactive: "streamsInactive",
      Pallette: "get_Pallette",
    }),
  },
}
</script>
