<template>
  <v-dialog
    v-model="dialogPreviewStream"
    persistent
    fullscreen
    key="dialog-preview-stream"
  >
    <v-card data-testid="container-preview-streams">
      <v-card-title>
        <span
          class="headline"
          v-html="getTitle()"
          data-testid="container-preview-streams-title"
        ></span>
      </v-card-title>
      <v-card-text>
        <div class="previewStreamWrapper">
          <video
            id="received_video"
            autoplay
            :controls="false"
            :webkit-playsinline="true"
            :playsinline="true"
            data-testid="video-preview-stream"
          ></video>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click.native="closeDialog()"
          data-testid="btn-close"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"

export default {
  name: "stream-preview-dialog",

  watch: {
    dialogPreviewStream: function (newVal, oldVal) {
      if (newVal) {
        this.startPreviewAspxStream()
      } else {
        this.stopPreviewAspxStream()
      }
    },
  },

  methods: {
    getTitle() {
      return this.streamActiveItem
        ? `<span class="blue-grey--text text--lighten-4">Preview Stream:</span> ${this.streamActiveItem.nickName}`
        : ""
    },
    startPreviewAspxStream() {
      let self = this
      setTimeout(() => {
        // self.stopPreviewAspxStream().then(() => {
        self.streamsPeerReceiver.makeOfferPreview(self.streamActiveItem)
        //   })
      }, 200)
    },
    stopPreviewAspxStream() {
      let self = this
      return new Promise((resolve, reject) => {
        self.streamsPeerReceiver.closeRemotesPreview().then(() => {
          self.streamsPeerReceiver.disconnect().then(() => {
            setTimeout(() => {
              self.$store.dispatch("setStreamActiveItem", {}).then(() => {
                resolve()
              })
            }, 300)
          })
        })
      })
    },
    closeDialog() {
      this.$store.dispatch("saveDialogPreviewStream", false)
    },
  },

  computed: {
    ...mapGetters({
      dialogPreviewStream: "dialogPreviewStream",
      streamActiveItem: "streamActiveItem",
      streamsPeerReceiver: "streamsPeerReceiver",
    }),
  },
}
</script>

<style lang="scss" scoped>
video {
  pointer-events: none;
  min-width: 100%;
  max-width: 100%;
  margin: auto;
  height: 75vh;
}

.previewStreamWrapper {
  max-height: calc(100vh - 88px);
}
</style>
