<template>
  <v-dialog
    v-model="dialogPreviewStreamHistory"
    persistent
    key="dialog-stream-history"
    max-width="600"
    content-class="dialog-streams-history"
  >
    <v-card data-testid="container-history-stream">
      <v-card-title>
        <span
          class="headline"
          v-html="getTitle()"
          data-testid="container-history-stream-title"
        ></span>
      </v-card-title>
      <v-card-text>
        <div
          v-if="streamActiveItem.histories && streamActiveItem.histories.length"
        >
          <v-data-table
            :headers="headers"
            :items="sortedHistory"
            class="elevation-0 border"
            :options.sync="options"
            :page.sync="options.page"
            :items-per-page.sync="options.itemsPerPage"
            data-testid="table-history-streams"
          >
            <template v-slot:[`item.ip`]="{ item }">
              <div data-testid="ip">
                {{ item.ip }}
              </div>
            </template>
            <template v-slot:[`item.startedAt`]="{ item }">
              <div data-testid="started">
                {{ item.startedAt | dateTimeFormat }}
              </div>
            </template>
            <template v-slot:[`item.endedAt`]="{ item }">
              <div data-testid="ended">
                <template v-if="item.endedAt">{{
                  item.endedAt | dateTimeFormat
                }}</template>
                <template v-else>
                  <v-chip
                    small
                    :color="Pallette.statusChips.active"
                    text-color="white"
                  >
                    Active
                  </v-chip>
                </template>
              </div>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click.native="closeDialog()"
          data-testid="btn-close"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"

export default {
  name: "stream-history-dialog",

  data: () => ({
    headers: [
      { text: "IP", value: "ip", sortable: true },
      { text: "Started", value: "startedAt", sortable: false },
      { text: "Ended", value: "endedAt", sortable: false },
    ],
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
    },
  }),

  watch: {
    dialog: function (newVal, oldVal) {
      if (newVal) {
        this.$emit("start-preview-aspx-stream")
      } else {
        this.$emit("stop-preview-aspx-stream")
      }
    },
  },

  methods: {
    closeDialog() {
      let self = this
      self.$store.dispatch("saveDialogPreviewStreamHistory", false).then(() => {
        setTimeout(() => {
          self.$store.dispatch("setStreamActiveItem", {}).then(() => {
            self.options.page = 1
          })
        }, 400)
      })
    },
    getTitle() {
      return this.streamActiveItem
        ? `<span class="blue-grey--text text--lighten-4">Stream history for ${this.streamType}:</span> ${this.streamActiveItem.nickName}`
        : ""
    },
  },

  computed: {
    ...mapGetters({
      Pallette: "get_Pallette",
      dialogPreviewStreamHistory: "dialogPreviewStreamHistory",
      streamActiveItem: "streamActiveItem",
    }),

    sortedHistory() {
      return [...this.streamActiveItem.histories].reverse()
    },
    streamType() {
      return this.streamActiveItem &&
        this.streamActiveItem.__t === "ReceiverStream"
        ? "receiver"
        : "sender"
    },
  },
}
</script>

<style lang="scss">
//.dialog-streams-history .v-card {
//  min-height: 500px;
//}
</style>
